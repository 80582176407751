import React, { useState, useCallback } from 'react';
import ImageViewer from 'react-simple-image-viewer';
import Layout from '../comp/Layout';
import styles from './index.module.scss';
import ydroboli from '../images/ydroboli/20170831_125409.webp';
import ydroboli2 from '../images/ydroboli/20181203_150217.webp';
import ydroboli3 from '../images/ydroboli/20191115_093748.webp';
import ydroboli4 from '../images/ydroboli/20191115_095748.webp';
import ydroboli5 from '../images/ydroboli/20191115_100650.webp';
import ydroboli6 from '../images/ydroboli/20191115_104839.webp';
import ydroboli7 from '../images/ydroboli/20191115_125305.webp';
import ydroboli8 from '../images/ydroboli/IMG_20190316_092059.webp';
import ydroboli9 from '../images/ydroboli/IMG_20190316_101758.webp';
import ydroboli10 from '../images/ydroboli/IMG_20200608_103704.webp';

import episkeues from '../images/episkeues/1.webp';
import episkeues2 from '../images/episkeues/2.webp';
import episkeues3 from '../images/episkeues/3.webp';
import episkeues4 from '../images/episkeues/20201102_094056.webp';
import episkeues5 from '../images/episkeues/20201102_094115.webp';
import episkeues6 from '../images/episkeues/20201102_104646.webp';
import episkeues7 from '../images/episkeues/IMG_20201024_153144.webp';
import episkeues8 from '../images/episkeues/IMG_20201024_153312.webp';
import episkeues9 from '../images/episkeues/IMG_20201121_121658.webp';
import episkeues10 from '../images/episkeues/IMG_20201121_121728.webp';
import episkeues11 from '../images/episkeues/IMG_20201121_122025.webp';
import episkeues12 from '../images/episkeues/IMG_20201121_122613.webp';
import episkeues13 from '../images/episkeues/IMG_20201229_111500.webp';
import episkeues14 from '../images/episkeues/IMG_20201229_111831.webp';
import episkeues15 from '../images/episkeues/IMG_20201229_112320.webp';
import episkeues16 from '../images/episkeues/IMG_20201229_115032.webp';
import episkeues17 from '../images/episkeues/IMG_20210123_081735.webp';
import episkeues18 from '../images/episkeues/IMG_20210123_104711.webp';
import episkeues19 from '../images/episkeues/IMG_20210123_104734.webp';
import episkeues20 from '../images/episkeues/IMG_20210123_104821.webp';
import episkeues21 from '../images/episkeues/IMG_20210123_104846.webp';
import episkeues22 from '../images/episkeues/IMG_20210123_104949.webp';
import episkeues23 from '../images/episkeues/IMG_20201229_124017.webp';

import ganait from '../images/ganait/20180215_222239_2.webp';
import ganait2 from '../images/ganait/20181215_110234.webp';
import ganait3 from '../images/ganait/20210316_120439.webp';
import ganait4 from '../images/ganait/DSCN6805.webp';
import ganait5 from '../images/ganait/DSCN6819.webp';
import ganait6 from '../images/ganait/IMG_20180907_175915_504.webp';
import ganait7 from '../images/ganait/IMG_20180907_175918_364.webp';
import ganait8 from '../images/ganait/IMG_20180907_175920_704.webp';
import ganait9 from '../images/ganait/IMG_20180907_175923_333.webp';
import ganait10 from '../images/ganait/IMG_20180907_175925_674.webp';
import ganait11 from '../images/ganait/IMG_20180907_180012_396.webp';
import ganait12 from '../images/ganait/IMG_20180907_180056_073.webp';
import ganait13 from '../images/ganait/IMG_20180907_180059_697.webp';
import ganait14 from '../images/ganait/IMG_20180907_180101_683.webp';
import ganait15 from '../images/ganait/IMG_20180907_180106_038.webp';
import ganait16 from '../images/ganait/IMG_20200807_092826.webp';
import ganait17 from '../images/ganait/IMG_20200807_115940.webp';
import ganait18 from '../images/ganait/IMG-2b66d3ffea73e0aa176e5e913560b0c4-V.webp';
import ganait19 from '../images/ganait/IMG-2d0aee9aa1589d2adba424391f18e373-V.webp';
import ganait20 from '../images/ganait/IMG-3f5bab070a720aaf008452c53cbb860a-V.webp';
import ganait21 from '../images/ganait/IMG-39a6f304dbe5414f4ad4c9f894cee7a3-V.webp';
import ganait22 from '../images/ganait/IMG-58ff56f248038fc064d3ec39debfd06a-V.webp';
import ganait23 from '../images/ganait/IMG-d49d69bd6861c51497f18769426843ee-V.webp';

import ammovoli from '../images/ammovoli/20181008_102714.webp';
import ammovoli2 from '../images/ammovoli/20181008_103014.webp';
import ammovoli3 from '../images/ammovoli/20191115_093748.webp';
import ammovoli4 from '../images/ammovoli/DSCN6831.webp';
import ammovoli5 from '../images/ammovoli/DSCN6839.webp';
import ammovoli6 from '../images/ammovoli/IMG_20210306_144927.webp';
import ammovoli7 from '../images/ammovoli/IMG_20210306_174132.webp';
import ammovoli8 from '../images/ammovoli/IMG_20210307_163419.webp';
import ammovoli9 from '../images/ammovoli/IMG_20210307_163855.webp';
import ammovoli10 from '../images/ammovoli/IMG_20210307_163937.webp';
import ammovoli11 from '../images/ammovoli/IMG_20210309_114603.webp';

import thermoprosopsi from '../images/thermoproswpsi/1.webp';
import thermoprosopsi2 from '../images/thermoproswpsi/2.webp';
import thermoprosopsi3 from '../images/thermoproswpsi/20210708_070613.webp';
import thermoprosopsi4 from '../images/thermoproswpsi/20210708_151110.webp';
import thermoprosopsi5 from '../images/thermoproswpsi/IMG_20190420_131548.webp';
import thermoprosopsi6 from '../images/thermoproswpsi/IMG_20190421_150434.webp';
import thermoprosopsi7 from '../images/thermoproswpsi/IMG_20190910_111548.webp';
import thermoprosopsi8 from '../images/thermoproswpsi/IMG_20190910_111708.webp';
import thermoprosopsi9 from '../images/thermoproswpsi/IMG_20200807_095426.webp';
import thermoprosopsi10 from '../images/thermoproswpsi/IMG_20200807_102225.webp';

import epixrismata from '../images/epixrismata/IMG_20181110_155309.webp';
import epixrismata2 from '../images/epixrismata/IMG_20181110_155401.webp';
import epixrismata3 from '../images/epixrismata/IMG_20190927_152003.webp';
import epixrismata4 from '../images/epixrismata/IMG_20191025_141547.webp';
import epixrismata5 from '../images/epixrismata/IMG_20191025_141734.webp';
import epixrismata6 from '../images/epixrismata/IMG_20200602_150605.webp';
import epixrismata7 from '../images/epixrismata/IMG_20200702_164318.webp';
import epixrismata8 from '../images/epixrismata/IMG_20200719_092749.webp';
import epixrismata9 from '../images/epixrismata/IMG_20201121_122025.webp';
import epixrismata10 from '../images/epixrismata/IMG_20201229_124017.webp';
import epixrismata11 from '../images/epixrismata/IMG_20210123_104711.webp';

import armologisi from '../images/armologisi/20170913_122310.webp';
import armologisi2 from '../images/armologisi/20181019_113947.webp';
import armologisi3 from '../images/armologisi/20181101_091809.webp';
import armologisi4 from '../images/armologisi/20191115_093813.webp';
import armologisi5 from '../images/armologisi/20191115_093941.webp';
import armologisi6 from '../images/armologisi/20191115_094603.webp';
import armologisi7 from '../images/armologisi/20191115_094626.webp';
import armologisi8 from '../images/armologisi/20191115_130550.webp';
import armologisi9 from '../images/armologisi/IMG_20200731_111641.webp';

const Album = () => {
  const images = {
    'ydroboli': [ydroboli, ydroboli2, ydroboli3, ydroboli4, ydroboli5, ydroboli6, ydroboli7, ydroboli8, ydroboli9, ydroboli10],
    'episkeues': [episkeues11, episkeues2, episkeues3, episkeues4, episkeues5, episkeues6, episkeues7, episkeues8, episkeues9, episkeues10,
      episkeues, episkeues12, episkeues13, episkeues14, episkeues15, episkeues16, episkeues17, episkeues18, episkeues19, episkeues20, 
      episkeues21, episkeues22, episkeues23],
    'gkanait':[ganait, ganait2, ganait3, ganait4, ganait5, ganait6, ganait7, ganait8, ganait8, ganait9, ganait10,
       ganait11, ganait12, ganait13, ganait14, ganait15, ganait16, ganait17, ganait18, ganait19, ganait20,
      ganait21, ganait22, ganait23],
    'ammovoli': [ammovoli5, ammovoli2, ammovoli3, ammovoli4, ammovoli, ammovoli6, ammovoli7, ammovoli8, ammovoli9, ammovoli10, ammovoli11],
    'thermoprosopsi': [thermoprosopsi, thermoprosopsi2, thermoprosopsi3, thermoprosopsi4, thermoprosopsi5, thermoprosopsi6, thermoprosopsi7, thermoprosopsi8,
      thermoprosopsi9, thermoprosopsi10],
    'epixrismata': [epixrismata, epixrismata2, epixrismata3, epixrismata4, epixrismata5, epixrismata6, epixrismata7, epixrismata8, epixrismata9,
    epixrismata10, epixrismata11],
    'armologisi': [armologisi, armologisi2, armologisi3, armologisi4, armologisi5, armologisi6, armologisi7, armologisi8, armologisi9],
  };

  const imagesNameMapper = {
    'ydroboli': 'ΥΔΡΟΒΟΛΕΣ',
    'episkeues': 'ΕΠΙΣΚΕΥΕΣ',
    'gkanait': 'ΓΚΑΝΑΙΤ',
    'ammovoli': 'ΑΜΜΟΒΟΛΕΣ',
    'thermoprosopsi': 'ΘΕΡΜΟΠΡΟΣΟΨΗ',
    'epixrismata': 'ΕΠΙΧΡΙΣΜΑΤΑ',
    'armologisi': 'ΑΡΜΟΛΟΓΗΣΗ',
  };

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(images['ydroboli']);

  const openImageViewer = useCallback((key, index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
    setCurrentCategory(images[key]);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return ( 
  <Layout title="Αλμπουμ">
    <main className={`${styles.mainContainer} page-margin`}>
      <br />
      <h2>ΑΛΜΠΟΥΜ</h2>

      <div style={{width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
        {Object.keys(images).map(key => 
          <div style={{position: 'relative', margin: '8px'}} className="album-box">
            <img src={images[key][0]} 
            alt={''+imagesNameMapper[key]+', '+key} width="400" height="211" key={0} 
            onClick={() => openImageViewer(key, 0)}
            />
            <div onClick={() => openImageViewer(key, 0)}
              style={{position: 'absolute', top: 'calc( 50% - 31.5px )', left: 'calc( 50% - 109.5px )',
              fontSize: '22px', color: 'white', padding: '15px', width: '219px',
              textAlign: 'center', background: 'rgba(19,27,35,0.5)', cursor: 'pointer'}}>
                {imagesNameMapper[key]}
            </div>
          </div>
        )}
      </div>

      {isViewerOpen && (
        <ImageViewer 
          src={currentCategory}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={true}
          closeOnClickOutside={true}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)"
          }}
        />
      )}
      
    </main>
  </Layout> )

}

export default Album;
